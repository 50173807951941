import * as React from 'react'
import { Link } from 'gatsby'
import Seo from '../components/seo'
import { Container } from '../components/style'
import { useIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'

// styles
const Main = styled.main`
  display: flex;
  padding: 5rem 2.5rem;
  background-color: #fff;
  color: #000;
`

const ContainerCenter = styled(Container)`
  justify-content: center;
`

const H1 = styled.h1`
  margin-top: 0;
  margin-bottom: 4rem;
  max-width: 320px;
`

const Paragraph = styled.p`
  margin-bottom: 48px;
`

const Code = styled.code`
  color: #8A6534;
  padding: 0.25rem;
  background-color: #FFF4DB;
  font-size: 1.25rem;
  border-radius: 0.25rem;
`

// markup
const NotFoundPage = () => {
  const intl = useIntl()
  const locale = intl.locale !== 'pl' ? `${intl.locale}` : 'pl'

  return (
    <React.Fragment>
      <Seo
        title={intl.formatMessage({ id: 'site_title' })}
        description={intl.formatMessage({ id: 'site_description' })}
        keywords={intl.formatMessage({ id: 'site_keywords' })}
        lang={locale}
      />
      <Main>
        <ContainerCenter>
          <H1>Nie odnaleziono strony</H1>
          <Paragraph>
            Przepraszamy, strona której szukasz nie istnieje
            <br />
            {process.env.NODE_ENV === 'development' ? (
              <>
                <br />
                Spróbuj stworzyć nową stronę w katalogu <Code>src/pages/</Code>.
                <br />
              </>
            ) : null}
            <br />
            <Link to="/" style={{ color: "#3567b7" }}>Powrót do strony głównej</Link>.
          </Paragraph>
        </ContainerCenter>
      </Main>
    </React.Fragment>
  )
}

export default NotFoundPage
